svg *:not([stroke]) {
  stroke: var(--color);
}

svg *:not([fill]) {
  fill: transparent;
}

.move:hover,
.look:hover,
.take:hover,
svg a:hover *,
g.look:hover *,
g.move:hover *,
g.take:hover * {
  fill: grey;
  cursor: pointer;
}

.card * {
  transition: x 1s, y 1s;
}

text {
  user-select: none;
}
