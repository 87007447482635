svg :not([stroke]) {
  stroke: var(--color);
}

svg :not([fill]) {
  fill: #0000;
}

.move:hover, .look:hover, .take:hover, svg a:hover *, g.look:hover *, g.move:hover *, g.take:hover * {
  fill: gray;
  cursor: pointer;
}

.card * {
  transition: x 1s, y 1s;
}

text {
  user-select: none;
}

/*# sourceMappingURL=index.86d5f941.css.map */
